<template>
    <section class="">
        <div class="mx-auto animate-pulse">
            <div class="grid grid-cols-2 gap-4 mt-2 xl:mt-12 md:grid-cols-3 lg:grid-cols-5">
                <div class="w-full ">
                    <div class="w-full h-36 bg-gray-300 rounded-lg dark:bg-gray-600"></div>
                </div>

                <div class="w-full ">
                    <div class="w-full h-36 bg-gray-300 rounded-lg dark:bg-gray-600"></div>
                </div>

                <div class="w-full hidden md:block">
                    <div class="w-full h-36 bg-gray-300 rounded-lg dark:bg-gray-600"></div>
                </div>

                <div class="w-full hidden lg:block">
                    <div class="w-full h-36 bg-gray-300 rounded-lg dark:bg-gray-600"></div>
                </div>

                <div class="w-full hidden lg:block">
                    <div class="w-full h-36 bg-gray-300 rounded-lg dark:bg-gray-600"></div>
                </div>
            </div>
        </div>
    </section>
</template>