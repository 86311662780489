<template>
    <a href="#" v-if="user && authToken">
        <img src="@/assets/images/avatars/placeholder.png" class="header_widgets_avatar mt-1 md:mt-0" alt="">
    </a>
    <div uk-drop="mode: click;offset:5" class="header_dropdown profile_dropdown" v-if="user && authToken">
        <ul>   
            <li>
                <a href="#" class="user">
                    <div class="user_avatar">
                        <img src="@/assets/images/avatars/placeholder.png" alt="">
                    </div>
                    <div class="user_name">
                        <div> {{ user.phone }} </div>
                        <span class="d-flex"> 
                            <span class="mx-1"> مشترك </span>
                            <span v-if="user.subscription.has_active_billing" style="color: green">نشط</span>
                            <span v-else style="color: red">غير نشط</span>
                        </span>

                        
                    </div>
                </a>
            </li>
            <li> 
                <hr>
            </li>
            <!-- <li> 
                <a href="#" class="is-link">
                    <ion-icon name="rocket-outline" class="is-icon"></ion-icon> <span>  Upgrade Membership  </span>
                </a>
            </li> 
            <li> 
                <hr>
            </li> -->
            <!-- <li> 
                <a href="#">
                    <ion-icon name="person-circle-outline" class="is-icon"></ion-icon>
                        ملفي الشخصي 
                </a>
            </li> -->
            <!-- <li> 
                <a href="#">
                    <ion-icon name="card-outline" class="is-icon"></ion-icon>
                    Subscriptions
                </a>
            </li> -->
            <!-- <li> 
                <a href="#">
                    <ion-icon name="color-wand-outline" class="is-icon"></ion-icon>
                    My Billing 
                </a>
            </li> -->
            <!-- <li>
                <a href="#">
                    <ion-icon name="settings-outline" class="is-icon"></ion-icon>
                    إعدادات الحساب  
                </a>
            </li> -->
            <!-- <li> 
                <hr>
            </li> -->
            <!-- <li> 
                <a href="#" id="night-mode" class="btn-night-mode" onclick="UIkit.notification({ message: 'Hmm...  <strong> Night mode </strong> feature is not available yet. ' , pos: 'bottom-right'  })">
                    <ion-icon name="moon-outline" class="is-icon"></ion-icon>
                        Night mode
                    <span class="btn-night-mode-switch">
                        <span class="uk-switch-button"></span>
                    </span>
                </a>
            </li> -->
            <!-- <li> 
                <a class="text-red-400 cursor-pointer" @click.prevent="unsubscribe()">
                    <ion-icon name="log-out-outline" class="is-icon"></ion-icon>
                    <span v-if="!unsubscribeLoading">إلغاء اﻹشتراك</span> 
                    <span v-else>جارِ إلغاء اﻹشتراك.</span>
                </a>
            </li> -->
            <li> 
                <a @click.prevent="logout()" class="cursor-pointer">
                    <ion-icon name="log-out-outline" class="is-icon"></ion-icon>
                    تسجيل الخروج 
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { reactive, ref } from "vue";

    const { logout } = require("@/functions")

    const authToken = ref(localStorage.getItem('AUTH_TOKEN'))
    const user = reactive(JSON.parse(localStorage.getItem('AUTH_USER')))

</script>

<style>

</style>