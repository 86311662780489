import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/pages/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import('../views/pages/auth/LoginView.vue')
  },
  {
    path: '/categories/:slug',
    name: 'Category',
    component: () => import('../views/pages/CategoryView.vue')
  },
  {
    path: '/courses/:slug',
    name: 'Courses',
    component: () => import('../views/pages/CoursesView.vue')
  },
  {
    path: '/courses/intro/:slug',
    name: 'CourseIntro',
    component: () => import('../views/pages/CourseIntroView.vue')
  },
  {
    path: '/courses/watch/:slug',
    name: 'CourseWatch',
    component: () => import('../views/pages/CourseWatchView.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/pages/student/ProfileView.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/pages/student/DashboardView.vue')
  },
  {
    path: '/dashboard/courses',
    name: 'MyCourses',
    component: () => import('../views/pages/student/MyCoursesView.vue')
  },
  {
    path: '/dashboard/wishlist',
    name: 'Wishlist',
    component: () => import('../views/pages/student/WishlistView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
