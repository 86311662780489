<template>
    <!-- Slideshow -->
    <div class="uk-position-relative uk-visible-toggle overflow-hidden mb-8 lg:-mt-20" tabindex="-1"
            uk-slideshow="animation: scale ;min-height: 200; max-height: 500 ;autoplay: true">
        
        <ul class="uk-slideshow-items rounded">
            <li>
                <div class="uk-position-cover" uk-slideshow-parallax="scale: 1.2,1.2,1">
                    <img src="@/assets/images/hero-1.jpg" class="object-cover" alt="" uk-cover>
                </div>
                <div class="container relative p-20 lg:mt-12 h-full"> 
                  <div uk-slideshow-parallax="scale: 1,1,0.8" class="flex flex-col justify-center h-full w-full space-y-3">
                      <h1 uk-slideshow-parallax="y: 100,0,0" class="lg:text-4xl text-2xl w-1/2 md-w-full text-dark font-semibold"> تعلم من أفضل اﻷساتذة والخبراء</h1>
                      <p uk-slideshow-parallax="y: 150,0,0" class="text-base text-dark font-medium pb-4 lg:w-1/2"> إختار من أكثر من +1000 فيديو تعليمي </p>
                      <!-- <a v-if="authToken" uk-slideshow-parallax="y: 200,0,50" href="#get-started" class="bg-opacity-90 bg-white py-2.5 rounded-md text-base text-center w-32"> إبدأ اﻵن </a> 
                      <a  v-else uk-slideshow-parallax="y: 200,0,50" href="https://dsplp.sd.zain.com/?p=6448858439" class="bg-opacity-90 bg-white py-2.5 rounded-md text-base text-center w-32"> إبدأ اﻵن </a>  -->
                  </div>
                </div>
            </li> 
            <li>
              <div class="uk-position-cover" uk-slideshow-parallax="scale: 1.2,1.2,1">
                  <img src="@/assets/images/hero-2.jpg" class="object-cover" alt="" uk-cover>
              </div>
              <div class="container relative p-20 lg:mt-12 h-full">  
                  <div uk-slideshow-parallax="scale: 1,1,0.8" class="flex flex-col justify-center h-full w-full space-y-3">
                      <h1 uk-slideshow-parallax="y: 100,0,0" class="lg:text-4xl text-2xl w-1/2 md-w-full text-dark font-semibold"> تعلم من أفضل اﻷساتذة والخبراء</h1>
                      <p uk-slideshow-parallax="y: 150,0,0" class="text-base text-dark font-medium pb-4 lg:w-1/2"> إختار من أكثر من +1000 فيديو تعليمي </p>
                      <!-- <a v-if="authToken" uk-slideshow-parallax="y: 200,0,50" href="#get-started" class="bg-opacity-90 bg-white py-2.5 rounded-md text-base text-center w-32"> إبدأ اﻵن </a> 
                      <a  v-else uk-slideshow-parallax="y: 200,0,50" href="https://dsplp.sd.zain.com/?p=6448858439" class="bg-opacity-90 bg-white py-2.5 rounded-md text-base text-center w-32"> إبدأ اﻵن </a>  -->
                  </div>
                </div>
          </li> 
          <li>
              <div class="uk-position-cover" uk-slideshow-parallax="scale: 1.2,1.2,1">
                  <img src="@/assets/images/hero-3.jpg" class="object-cover" alt="" uk-cover>
              </div>
              <div class="container relative p-20 lg:mt-12 h-full">  
                  <div uk-slideshow-parallax="scale: 1,1,0.8" class="flex flex-col justify-center h-full w-full space-y-3">
                      <h1 uk-slideshow-parallax="y: 100,0,0" class="lg:text-4xl text-2xl w-1/2 md-w-full text-dark font-semibold"> تعلم من أفضل اﻷساتذة والخبراء</h1>
                      <p uk-slideshow-parallax="y: 150,0,0" class="text-base text-dark font-medium pb-4 lg:w-1/2"> إختار من أكثر من +1000 فيديو تعليمي </p>
                      <!-- <a v-if="authToken" uk-slideshow-parallax="y: 200,0,50" href="#get-started" class="bg-opacity-90 bg-white py-2.5 rounded-md text-base text-center w-32"> إبدأ اﻵن </a> 
                      <a  v-else uk-slideshow-parallax="y: 200,0,50" href="https://dsplp.sd.zain.com/?p=6448858439" class="bg-opacity-90 bg-white py-2.5 rounded-md text-base text-center w-32"> إبدأ اﻵن </a>  -->
                  </div>
                </div>
          </li> 
        </ul> 
        
        <a class="uk-position-center-left-out uk-position-small uk-hidden-hover slidenav-prev" href="#"
            uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right-out uk-position-small uk-hidden-hover slidenav-next" href="#"
            uk-slideshow-item="next"></a>

    </div> 
</template>

<script setup>

    const authToken = localStorage.getItem('AUTH_TOKEN')

</script>

<style>

</style>