<template>
    <section class="">
        <div class="mx-auto animate-pulse">
            <div class="grid grid-cols-1 gap-4 mt-6 xl:mt-12 md:grid-cols-2 lg:grid-cols-4">
                <div class="w-full ">
                    <div class="w-full h-56 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

                    <h1 class="w-52 h-3 mx-2 mt-5 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
                    <p class="w-24 h-2 mx-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                </div>

                <div class="w-full hidden md:block">
                    <div class="w-full h-56 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

                    <h1 class="w-52 h-3 mx-2 mt-5 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
                    <p class="w-24 h-2 mx-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                </div>

                <div class="w-full hidden md:block">
                    <div class="w-full h-56 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

                    <h1 class="w-52 h-3 mx-2 mt-5 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
                    <p class="w-24 h-2 mx-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                </div>

                <div class="w-full hidden lg:block">
                    <div class="w-full h-56 bg-gray-300 rounded-lg dark:bg-gray-600"></div>

                    <h1 class="w-52 h-3 mx-2 mt-5 bg-gray-200 rounded-lg dark:bg-gray-700"></h1>
                    <p class="w-24 h-2 mx-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                </div>
            </div>
        </div>
    </section>
</template>