<template>
    <!--  slider newest courses --> 
    <div class="sm:my-4 my-3 flex items-end justify-between pt-3">
        <h2 class="text-2xl font-semibold"> أحدث الكورسات  </h2>
        <router-link :to="{ name: 'Courses', params: { slug: 'newest' }}" class="text-blue-500"> عرض الكل </router-link>
    </div> 

    <div class="mt-3">

        <h4 class="py-3 border-b font-semibold text-grey-700  mx-1 mb-4" hidden> <ion-icon name="star"></ion-icon> Featured today </h4>
 
        <CoursesCaruselSkeleton v-if="!isFinished" />

        <div v-else class="relative" uk-slider="finite: true">

            <div class="uk-slider-container px-1 py-3">
                <ul class="uk-slider-items uk-child-width-1-4@m uk-child-width-1-2@s uk-grid-small uk-grid">
                    
                    <li v-for="course in data.data" :key="course.id">

                        <router-link :to="{ name: 'CourseIntro', params: { slug: course.slug } }" class="uk-link-reset">
                            <div class="bg-white shadow-sm rounded-lg uk-transition-toggle">
                                <div class="w-full h-60 overflow-hidden rounded-t-lg relative">
                                    <img :src="course.thumbnail_url" alt="" class="w-full h-full absolute inset-0 object-cover">
                                    <img src="@/assets/images/icon-play.svg" class="w-12 h-12 uk-position-center uk-transition-fade" alt="">
                                </div>
                                <div class="p-4">
                                    <div class="font-semibold line-clamp-2">{{ course.title }}</div>
                                    <div class="flex space-x-2 space-x-reverse items-center text-sm pt-3">
                                        <div>  {{ course.duration }} ساعات  </div>
                                        <div>·</div>
                                        <div> {{ course.videos_count }} محاضرة </div>
                                    </div>
                                    <div class="pt-1 flex items-center justify-between">
                                        <!-- <div class="text-sm text-green-600"> Ragaey Sameh </div> -->
                                        <!-- <div class="text-xs font-semibold"> 12h ago  </div> -->
                                    </div>
                                </div>
                            </div>
                        </router-link>

                    </li>
                </ul>

                <a class="absolute bg-white top-1/4 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                <a class="absolute bg-white top-1/4 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

            </div>
        </div>

    </div>
</template>

<script setup>

    import CoursesCaruselSkeleton from '@/components/skeleton/CoursesCaruselSkeleton.vue'
    import { axiosInstance } from '@/axios'
    import { useAxios } from '@vueuse/integrations/useAxios'


    const { data, isFinished } = useAxios('/courses', axiosInstance)

</script>

<style>

</style>