import { axiosInstance } from "./axios"

const logout = () => {
    axiosInstance.post('auth/logout').then(response => {
        clearUserSession()
        reloadPage()
    }).catch ({ })
}

const clearUserSession = () => {
    localStorage.removeItem('AUTH_TOKEN')
    localStorage.removeItem('AUTH_USER')
}

const reloadPage = () => {
    location.reload()
}

export {
    logout,
    clearUserSession,
    reloadPage
}