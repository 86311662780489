<script setup>

    import CategoriesSkeleton from '@/components/skeleton/CategoriesSkeleton.vue'
    import { axiosInstance } from '@/axios'
    import { useAxios } from '@vueuse/integrations/useAxios'


    const { data, isFinished } = useAxios('/categories', axiosInstance)

</script>

<template>
   <!--  Categories -->
   <div class="categories">

        <div class="sm:my-8 my-3 flex items-end justify-between">
            <div>
                <h2 class="text-2xl font-semibold"> التصنيفات </h2>
                <p class="font-medium text-gray-500 leading-6"> ابحث عن موضوع من خلال تصفح أفضل التصنيفات. </p>
            </div>
            <!-- <a href="#" class="text-blue-500"> عرض الكل </a> -->
        </div> 

        <CategoriesSkeleton v-if="!isFinished" />

        <div v-else class="relative -mt-3" uk-slider="finite: true" >

            <div class="uk-slider-container px-1 py-3">
                <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
                    <li v-for="category in data.data" :key="category.id" class="category">
                        <router-link :to="{ name: 'Courses', params: { slug: 'newest' }, query: { category: category.id }}">
                            <div class="rounded-md overflow-hidden relative w-full h-36">
                                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                                </div>
                                <img :src="category.icon_url" class="cat-img absolute w-full h-full object-scale-down" alt="" :style="{'background': category.color}">
                                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold sm:text-sm md:text-md text-center"> {{ category.title }} </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
            
            <a class="absolute bg-white top-16 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
            <a class="absolute bg-white top-16 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

        </div>

    </div>
</template>

<style scoped>

    .category .cat-img {
        /* scale: 2;
        top: -20px */
    }

</style>